<template>
  <b-container fluid class="px-sm-5 position-relative index-1">
    <b-row class="no-gutters mt-4 pb-4">
      <b-col col md="5" class="align-self-center">
        <h1 class="color-white line-height-1">RADIFY AI<span class="superscript">&reg;</span> | <small><small>MEET THE AI CHALLENGE</small></small></h1>
        <span class="border-separator"></span>
        <h4 class="color-white mb-4">
          Be part of the movement to democratise healthcare.
        </h4>
        <b-form @submit.prevent="submit" v-if="registered">
          <b-form-group id="name-group" label-for="name">
            <b-form-input
              v-model="user.username"
              id="form_name"
              type="text"
              required
              placeholder="Please enter your email or mobile number"
              data-error="Email or mobile number is required."
            ></b-form-input>
          </b-form-group>
          <b-form-group id="email-group" label-for="email">
            <b-form-input
              v-model="user.password"
              id="form_password"
              type="password"
              required
              placeholder="Please enter your password"
            ></b-form-input>
          </b-form-group>
          <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
          <div class="float-right">
            <p class="text-white mb-0 mr-1 font-size-12">Not registered? <a href="#" @click.prevent="registered = false">Switch to guest.</a></p>
          </div>
          <b-button :disabled="formStatus==='submitting'" type="submit" pill class="btn-yellow mb-4 px-5">Proceed</b-button>
        </b-form>
        <b-form @submit.prevent="submit" v-else>
          <b-form-group id="alias-group" label-for="form_alias">
            <b-form-input
              v-model="guest.name"
              id="form_alias"
              type="text"
              required
              placeholder="Please enter your name or alias"
              data-error="Name or alias is a required field."
            ></b-form-input>
          </b-form-group>
          <b-form-group id="email-group" label-for="form_alias">
            <b-form-input
              v-model="guest.email"
              id="form_email"
              type="text"
              required
              placeholder="Please enter your email address"
              data-error="Email address is a required field."
            ></b-form-input>
          </b-form-group>
          <b-form-group id="phone-group" label-for="form_phone">
            <b-form-input
              v-model="guest.phone"
              id="form_phone"
              type="text"
              placeholder="Please enter your mobile number, optional"
            ></b-form-input>
          </b-form-group>
          <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
          <div class="float-right">
            <p class="text-white mb-0 mr-1 font-size-12">Registered user? <a href="#" @click.prevent="registered = true">Switch to login.</a></p>
          </div>
          <b-button :disabled="formStatus==='submitting'" type="submit" pill class="btn-yellow mb-4 px-5">Proceed</b-button>
        </b-form>
        <p class="color-white mb-5">
          <strong class="color-white d-block mb-2">CONTACT US</strong>Should you
          have any feedback, comments, requests for technical support or other
          inquiries, please contact us by email:
          <a href="mailto:info@envisionit.ai">info@envisionit.ai</a>.
        </p>
      </b-col>
    </b-row>
    <b-modal id="register" size="lg" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <button type="button" aria-label="Close" class="close" @click="closeRegister()">×</button>
      <h4 class="mb-4">A few more details ...</h4>
      <b-form @submit.prevent="submitExtra">
        <b-row>
          <b-col cols="12" md="6" class="pr-md-2">
            <b-form-group id="gender-group" label-for="extra_gender">
              <b-form-select
                v-model="extra.gender"
                id="extra_gender"
                :options="genderOptions"
                required
              >
              <template #first>
                <b-form-select-option :value="null" disabled>Please select your gender</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="pl-md-2">
            <b-form-group id="age-group" label-for="extra_age">
              <b-form-select
                v-model="extra.age"
                id="extra_age"
                :options="ageOptions"
                required
              >
              <template #first>
                <b-form-select-option :value="null" disabled>Please select your age</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="pr-md-2" v-if="extra.gender === 'Prefer to self-describe'">
            <b-form-group id="gender_other-group" label-for="form_gender_other">
              <b-form-input
                v-model="extra.gender_other"
                id="form_gender_other"
                type="text"
                required
                placeholder="Please provide your self description"
                data-error="Self description is a required field."
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="pr-md-2">
            <b-form-group id="role-group" label-for="extra_role">
              <b-form-select
                v-model="extra.job_role"
                id="extra_role"
                :options="roleOptions"
                required
              >
              <template #first>
                <b-form-select-option :value="null" disabled>Please select your job role</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="pl-md-2">
            <b-form-group id="years-group" label-for="extra_years">
              <b-form-select
                v-model="extra.years"
                id="extra_years"
                :options="yearsOptions"
                required
              >
              <template #first>
                <b-form-select-option :value="null" disabled>Please select your years of experience</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="pr-md-2" v-if="extra.job_role === 'Other'">
            <b-form-group id="job_role_other-group" label-for="form_job_role_other">
              <b-form-input
                v-model="extra.job_role_other"
                id="form_job_role_other"
                type="text"
                required
                placeholder="Please provide your job role"
                data-error="Job role is a required field."
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="px-1">
          We would like to request your participation in an upcoming survey. The purpose of this survey is to gain insights into the use of AI in radiology medical education.
        </p>
        <b-form-group id="participate-group" label-for="extra_participate" class="mx-1">
          <b-form-checkbox
            v-model="extra.participate"
            id="extra_participate"
            name="extra_participate"
            switch
            :value="true"
            :unchecked-value="false"
          >I would like to participate in an upcoming survey.</b-form-checkbox>
        </b-form-group>
        <b-form-group id="confirm1-group" label-for="extra_confirm1" class="mx-1" v-if="extra.participate">
          <b-form-checkbox
            v-model="extra.confirm1"
            id="extra_confirm1"
            name="extra_confirm1"
            :value="true"
            :unchecked-value="false"
          >I understand that my participation is voluntary and I am free to withdraw at any time without giving any reasons.</b-form-checkbox>
        </b-form-group>
        <b-form-group id="confirm2-group" label-for="extra_confirm2" class="mx-1" v-if="extra.participate">
          <b-form-checkbox
            v-model="extra.confirm2"
            id="extra_confirm2"
            name="extra_confirm2"
            :value="true"
            :unchecked-value="false"
          >I understand that by taking part in this study my name/alias will be anonymous and my performance scores in this workshop will not be listed or mentioned in any publication.</b-form-checkbox>
        </b-form-group>
        <b-form-group id="confirm3-group" label-for="extra_confirm3" class="mx-1">
          <b-form-checkbox
            v-model="extra.confirm3"
            id="extra_confirm3"
            name="extra_confirm3"
            :value="true"
            :unchecked-value="false"
          >I confirm that I will not store any patient images from the workshop onto my personal electronic device (either by screen shot or copying/pasting).</b-form-checkbox>
        </b-form-group>
        <b-button :disabled="formStatus === 'submitting' || (extra.participate && !(extra.confirm1 && extra.confirm2)) || !extra.confirm3" type="submit" pill class="btn-yellow px-5">Proceed</b-button>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      formStatus: null,
      user: {
        username: null,
        password: null,
        recaptcha: null
      },
      guest: {
        name: null,
        email: null,
        phone: null,
        recaptcha: null
      },
      extra: {
        gender: null,
        gender_other: null,
        age: null,
        job_role: null,
        job_role_other: null,
        years: null,
        participate: true,
        confirm1: false,
        confirm2: false,
        confirm3: false
      },
      options: {
        genders: ['Female', 'Male', 'Non-binary / Third gender', 'Prefer to self-describe', 'Prefer not to say'],
        ages: ['21-25', '26-30', '31-35', '36-40', '41-45', '46-50', '51-55', '56-60', '61+'],
        roles: ['Radiology Consultant', 'Radiology Trainee', 'Paediatric Radiology Fellow', 'Allied Health Professional/Radiographer', 'Other'],
        years: ['<1 year', '1-5 years', '6-10 years', '11-15 years', '16-20 years', '21+ years']
      },
      registered: false
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      status: state => state.auth.status,
      _user: state => state.user
    }),
    guestStatus () {
      return this._user.completed && (!this._user.info || !this._user.info.confirm3)
    },
    guestStatusExtra () {
      return this._user.completed && this._user.info && this._user.info.confirm3
    },
    genderOptions () {
      return this.options.genders.map(_gender => {
        return {
          value: _gender,
          text: _gender
        }
      })
    },
    ageOptions () {
      return this.options.ages.map(_age => {
        return {
          value: _age,
          text: _age
        }
      })
    },
    roleOptions () {
      return this.options.roles.map(_role => {
        return {
          value: _role,
          text: _role
        }
      })
    },
    yearsOptions () {
      return this.options.years.map(_year => {
        return {
          value: _year,
          text: _year
        }
      })
    }
  },
  created () {
    this.logout()
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('totals', {
      clearTotals: 'clear'
    }),
    ...mapActions('user', {
      update: 'update',
      updateExtra: 'updateExtra',
      clear: 'clear'
    }),
    submit: function () {
      this.formStatus = 'submitting'
      if (this.registered) {
        this.login(this.user)
      } else {
        this.update(this.guest)
      }
    },
    submitExtra: function () {
      this.formStatus = 'submitting'
      this.updateExtra({
        id: this._user.info._id,
        extra: this.extra
      })
    },
    resetFields: function () {
      this.formStatus = null
      this.user = {
        username: null,
        password: null,
        recaptcha: null
      }
      this.guest = {
        name: null,
        email: null,
        phone: null,
        recaptcha: null
      }
      this.extra = {
        gender: null,
        gender_other: null,
        age: null,
        job_role: null,
        job_role_other: null,
        years: null,
        participate: true,
        confirm1: false,
        confirm2: false,
        confirm3: false
      }
    },
    closeRegister: function () {
      this.clear()
      this.resetFields()
      this.$bvModal.hide('register')
    }
  },
  watch: {
    'user.username' (value) {
      this.formStatus = null
    },
    'user.password' (value) {
      this.formStatus = null
    },
    guestStatus () {
      if (this.guestStatus) {
        this.$bvModal.show('register')
        this.formStatus = null
      }
    },
    guestStatusExtra () {
      if (this.guestStatusExtra) {
        this.clearTotals()
        this.$router.push('/')
      }
    }
  }
}
</script>
